
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

import ActivitesTickets from "@/views/still/deploy/activitesTickets.vue";
import SyntheseProjet from "@/views/still/deploy/syntheseProjet.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    ActivitesTickets,
    SyntheseProjet
  },
  setup() {

    const router = useRouter();

    const affEquipByType = (type) => {
      router.push({  name: "listEquipementbyType", params: { code: type } });
    }

    const state = reactive({
      loaderEnabled: true,
      myList: [],
      lSites: [],
    });

    const Plur = (item) => {
      if (item>0) return 's';
    }



    onMounted(async () => {

      let myList = await getAxios("/getOverviewDeploy");
      state.myList = myList.results;
      state.lSites = myList.lsites;

      state.loaderEnabled = false;
    });

    return {
      state,
      affEquipByType,
      Plur,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
