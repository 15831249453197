<template>
<div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <div class="d-flex flex-column flex-xl-row" v-if="!state.loaderEnabled">
    <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <div class="card mb-5 mb-xl-8" style='min-height: 410px'>
        <div class="card-header border-0 pt-5 mt-5">
          <h3 class="card-title align-items-start flex-column mb-5">
            <span class="card-label fw-bolder fs-3 mb-1">Vue d'ensemble</span>

            <span class="text-muted mt-1 fw-bold fs-7" v-if="!state.loaderEnabled">En quelques chiffres</span>
          </h3>
        </div>

          <router-link to="/addupdateticket" class="link-primary fs-6 fw-bolder btn btn-primary mt-7 mx-7 mb-7">
            Créer un nouveau ticket
          </router-link>
        
        <div class="card-body pt-5">
          <h4 class="card-title align-items-start flex-column mb-1 mx-5 text-primary">{{ state.myList.tot_ticket }} Ticket{{ Plur(state.myList.tot_ticket) }}</h4>
                    
          <router-link href="#" class="btn btn-flex btn-light btn-color-primary px-6" style="width: 100%"  to="/stilldeploy/ticket">
            <span class="svg-icon svg-icon-2x"><i class="bi bi-gear fs-1"></i></span>
            <span class="d-flex flex-column align-items-start ms-2 mt-3 mb-3">
              <span class="fs-7 text-gray-700">{{ state.myList.tot_ticket_en_cours }} ticket{{ Plur(state.myList.tot_ticket_en_cours) }} en cours de traitement</span>
              <span class="fs-7 text-gray-700">{{ state.myList.tot_ticket_traites }} ticket{{ Plur(state.myList.tot_ticket_traites) }} traité{{ Plur(state.myList.tot_ticket_traites) }}</span>
            </span>
          </router-link>

          <h4 class="card-title align-items-start flex-column mt-10 mb-1 mx-5 text-primary">{{ state.myList.tot_stock }} Référence{{ Plur(state.myList.tot_stock) }} en stock</h4>
          
          <router-link href="#" class="btn btn-flex btn-light btn-color-primary px-6 " style="width: 100%" to="/stilldeploy/stock">
            <span class="svg-icon svg-icon-2x"><i class="bi bi-hdd-network fs-1"></i></span>
            <span class="d-flex flex-column align-items-start ms-2 mt-3 mb-3">
              <span class="fs-7 text-gray-700">{{ state.myList.tot_dispo }} référence{{ Plur(state.myList.tot_dispo) }} disponible{{ Plur(state.myList.tot_dispo) }}</span>
              <span class="fs-7 text-gray-700">{{ state.myList.tot_reappro }} référence{{ Plur(state.myList.tot_reappro) }} en réappro.</span>
              <span class="fs-7 text-gray-700">{{ state.myList.tot_epuise }} référence{{ Plur(state.myList.tot_epuise) }} épuisée{{ Plur(state.myList.tot_epuise) }}</span>
            </span>
          </router-link>

          <h4 class="card-title align-items-start flex-column mt-10 mb-1 mx-5 text-primary">{{ state.myList.tot_sites }} Sites</h4>
          <router-link href="#" class="btn btn-flex btn-light btn-color-primary px-6" style="width: 100%" to="/stilldeploy/sites">
            <span class="svg-icon svg-icon-3x"><i class="bi bi-pin-map fs-1"></i></span>
            <span class="d-flex flex-column align-items-start ms-2 mt-3 mb-3">
              <template v-for="(item, index) in state.lSites" :key="index">
                <span class="fs-7 text-gray-700" style="text-align: left">{{ item.tot }} sites "{{ item.adr_c_site_type }}"</span>
              </template>
            </span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex-lg-row-fluid ms-lg-5">
      <div class="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
        <div class="row g-5 g-xl-12">
          <div class="col-xl-12">

            <SyntheseProjet widget-classes="mb-12"></SyntheseProjet>

          </div>
        </div>
        <div class="row g-5 g-xl-12">
          <div class="col-xl-12">
            <ActivitesTickets widget-classes="mb-12"></ActivitesTickets>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

import ActivitesTickets from "@/views/still/deploy/activitesTickets.vue";
import SyntheseProjet from "@/views/still/deploy/syntheseProjet.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    ActivitesTickets,
    SyntheseProjet
  },
  setup() {

    const router = useRouter();

    const affEquipByType = (type) => {
      router.push({  name: "listEquipementbyType", params: { code: type } });
    }

    const state = reactive({
      loaderEnabled: true,
      myList: [],
      lSites: [],
    });

    const Plur = (item) => {
      if (item>0) return 's';
    }



    onMounted(async () => {

      let myList = await getAxios("/getOverviewDeploy");
      state.myList = myList.results;
      state.lSites = myList.lsites;

      state.loaderEnabled = false;
    });

    return {
      state,
      affEquipByType,
      Plur,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>
